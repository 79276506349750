import axios from 'axios';

const THP_API_TOKEN = "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIzIiwianRpIjoiOTRiN2NmYjllMDc2M2QwOGNhNzgzZTY1NDlmNmJlY2U1N2M2ZDIzYmEzZGQxOTlkZjc3YWY2M2ZjYWE3YjA5ZjZhYWFlY2NmYmFhNDgyNzkiLCJpYXQiOjE3MzI1MjAxNzkuOTgwOTQ1LCJuYmYiOjE3MzI1MjAxNzkuOTgwOTUsImV4cCI6MTc2NDA1NjE3OS45MzMyMTEsInN1YiI6IjEiLCJzY29wZXMiOltdfQ.Xg2dx4iUKRkL4rG73gx1uHYGipkJIntAutMbDIyb5Cj0351KsTHC990W7Ao4SFqgleDTgto3hutp-ZlYJNPVry0Eb85vTrpZkfNQWpuRgzlLNZ4TyFz6YfRZMGqB7yioCD7jkK8uAjdP264E4o4fPiCedE-De4J4PrMfmwc-xxrO-Z5mUOuW-igHsoAjDvFmZxFFc_EADoIorkl4CIX3aBif9gUYAgLFb5xrwdrHpgq3TOlhlvRJs-obh_05uRcTXvWT5XyCXcaIxk0jJf-_Rc5jcwAtzGZ2sxxFhHU1mob4arDoJrbJdUdCzj4hx-7f1d52BAhxwAWpzUDUmoXZNtYOt1OuRH0aezQKsWBgQv-gGP5VvvxE-k-ksfuIyi1RcBZ4_5SThekb1RVR_yyrfpr8BMpDCLQBFPyqibnTuir6jUA2JrWWj0o2mmp_ox5TbZm7PCbqQLTg6cXRtqVglRjhm3egqhwE_MmBSbz4DXu7mwnKXFEIxtNqsrTRTx6-xPM-0Zp1nmmiq5mDC1cFnbUT8_B5prigG-qouRiQZmlAczjpzax_iMMg0JCu5zDSrIlmBDy_KEU7xlhHjFDHTnqecq9cRL5j6nQbkofqm27uUugHJAPLrNlIsjeInhUp9SDWzntxyQdwxPA9l_BZI4l5DQy4z1Ps8F7QQG9meW4"
const THP_API_APPID = 1234560

const FORETHOUGHT_API_TOKEN = "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI5IiwianRpIjoiMGM3ZjFjM2FhYmM0ZWVlNmZjM2Q5MTNlMzZlNzRhYWYxNjZkNGQxYTYzODY2MTFmMzY3ZGQxNGFiNzI1OTdhMmM5NmZiODU5MDNlYzZlNTgiLCJpYXQiOjE3MjI0OTY2MTEuMjA0NTEzLCJuYmYiOjE3MjI0OTY2MTEuMjA0NTE2LCJleHAiOjE3NTQwMzI2MTEuMjAwNjU2LCJzdWIiOiIyNjIiLCJzY29wZXMiOltdfQ.mvBz9EMe4r-PPE6jv6VHdYCAkuy0tjTKZ-s6WZ36hIa_pggKnflbeoAs6HHdohOMPetd0PVWtgjCnT8o1HFqiRrNUfVkOXWl0DJeOgsiwh_0Ul2QCqAiUQNjPKP9TLM6yycT9YrOv0S0luz3peXFidyz9U8NKo3hNHNOG5d42AkwzKgWWu8HdvtIDxkImDDxzc0LW65P5YRGNRJ_bY0tlKB6sNkAt7Bul-eqqXtkQhGX71EkWYfDZy310wR8bQP6dIl1cAM3S-P-MfO4mZb8xkvhqVeAl1V3Nk0htKe3IiAaf_fZAgSpQl37VJAzkieLcxk-cgVDVhwVUgJ5KQqTnXiX-aRTXWShQRccwuIXmBcMBFyhZRQXeANsQjJzUEepWFhEyO3p1R8B-Dmc0_gIrs7oEL98uZBGNZ5zv-1JQvOIXtNTZ0AmCS6DjOZEIMc40hv7tbWibnn4g2Vb3Uh5xJp0gsgc6AXp9lE9-vFbGb9PL4PngCpXTt2SQhFbo71KyZ15ZZt0H2-P0sEBO_kMndQUoBZwSqW_vHsNBRBnjj_NSan0eRkWFWKjikbDaQAIZ8OoUZU74Qgn2FCoZ969FW4ziovx-XSjYpvARSve8qFCfTukjeE5hDIzmeV3dHybZ-5GZRq7Beq3LsJwRm8M0IJupcSmChhdBjIiHpcUbeo"
const FORETHOUGHT_API_APPID = 1234562

const BASE_URL = process.env.REACT_APP_BASEURL;
// const GetClientAppId = appTokenHandler();
// const GetClientTokenId = process.env.REACT_APP_API_TOKEN;

const { GetClientAppId, GetClientTokenId } = appTokenHandler();
// console.debug('appTokenHandler : Subdomain:', GetClientAppId);
// console.debug('appTokenHandler : Subdomain:', GetClientTokenId);

function appTokenHandler() {
  // Get the full URL
  const url = window.location.href;

  // Create a URL object for easier parsing
  const urlObj = new URL(url);

  // Extract the hostname (includes subdomain and domain)
  const hostname = urlObj.hostname;

  // Split hostname into parts
  const parts = hostname.split('.');

  // Assuming a standard domain structure of 'subdomain.domain.tld'
  const subdomain = parts.length > 2 ? parts.slice(0, -2).join('.') : '';
  const domain = parts.slice(-2).join('.');


  if (subdomain == 'careers' && domain == 'thehigherpitch.com') {
    console.debug('appTokenHandler : if Block Subdomain:', subdomain);
    console.debug('appTokenHandler : if Block  Domain:', domain);
    return { GetClientAppId: THP_API_APPID, GetClientTokenId: THP_API_TOKEN }
  } else {
    console.debug('appTokenHandler : else Block Subdomain:', subdomain);
    console.debug('appTokenHandler : else Block Domain:', domain);
    return { GetClientAppId: FORETHOUGHT_API_APPID, GetClientTokenId: FORETHOUGHT_API_TOKEN }
  }

  // return process.env.REACT_APP_API_APPID;
}
class APIService {
  constructor(baseURL) {
    this.baseURL = baseURL || BASE_URL;
    this.api = axios.create({
      baseURL: this.baseURL
    });

    // Define your endpoints here
    this.endpoints = {

      // ---------- expiredFilter List API ------------
      expiredfilterApiList: "api/v1/expired-jobs/filter",

      // ---------- Jobs View & List API ------------
      // jobApiView: "api/v1/job",
      jobApiList: "api/v1/job",
      // jobApiFilter:"api/v1/jobs/filter",
      jobApiFilter: "api/v1/jobs/new-filter",



      // ---------- Location API List & View  ------------
      locationApiList: "api/v1/locations",
      locationApiView: "api/v1/locations/",

      // ---------- City API List  ------------
      cityApi: "api/v1/city",

      // ---------- Job Filter API List  ------------
      jobFilterApiList: "api/v1/jobs/filter",

      // ---------- Department API List  ------------
      departmentApiList: "api/v1/department",

      // ---------- Branch API List  ------------
      branchApiList: "api/v1/branch",

      // ---------- Designation API List  ------------
      designationApiList: "api/v1/designation",

      // ---------- Default Image API List  ------------
      defaultImageApiList: "api/v1/default-image",

      // ---------- Question Template API List  ------------
      questionTemplateApiList: "api/v1/question-group",

      // ---------- Applicant Status API View  ------------
      applicantStatusApiView: "api/v1/applicant-status/",

      // ---------- Seo Keyword API View  ------------
      seoKeywordApiView: "api/v1/seo-keyword/",

      // ---------- Seo Graph API View  ------------
      seoGraphApiView: "api/v1/seo-graph",

      // ---------- Source API View  ------------
      sourceApiView: "api/v1/source",

      // ---------- createJobApplication API ------------
      createJobApplication: "api/v1/job-application",
      // https://gatewayapi.eela.tech/api/v1/job-application?app=1234560
    };
  }

  async get(endpointKey, params = {}, searchParam = null) {
    try {
      let endpoint = this.endpoints[endpointKey];

      params.app = GetClientAppId; // Note: Use function call instead of invoking directly

      const response = await this.api.get(endpoint, {
        params,
        headers: { Authorization: 'Bearer ' + GetClientTokenId },
      });
      return response.data; // Return data directly
    } catch (error) {
      throw new Error('API request failed:', error);
    }
  }

  async view(endpointKey, id = null, params = {}, searchParam = null) {
    try {
      let endpoint = this.endpoints[endpointKey];
      if (id) {
        endpoint = endpoint + '/' + id;
      }
      params.app = GetClientAppId; // Note: Use function call instead of invoking directly

      const response = await this.api.get(endpoint, {
        params,
        headers: { Authorization: 'Bearer ' + GetClientTokenId },
      });
      return response.data; // Return data directly
    } catch (error) {
      throw new Error('API request failed:', error);
    }
  }

  async post(endpointKey, data = null, params = {}, searchParam = null) {
    console.debug("data", data)
    try {
      let endpoint = this.endpoints[endpointKey];
      if (searchParam) {
        endpoint = endpoint + '/' + searchParam;
      }
      data.app = GetClientAppId;
      params.app = GetClientAppId;
      const response = await this.api.post(endpoint, data, {
        params,
        headers: { Authorization: 'Bearer ' + GetClientTokenId }
      });
      return response;
    } catch (error) {
      throw new Error('API request failed:', { error });
    }
  }

  async delete(endpointKey, params = {}, searchParam = null) {
    try {
      let endpoint = this.endpoints[endpointKey];
      if (searchParam) {
        endpoint = endpoint + '/' + searchParam;
      }
      params.app = GetClientAppId();
      const response = await this.api.delete(endpoint, {
        params,
        headers: { Authorization: 'Bearer ' + GetClientTokenId() }
      });
      return response;
    } catch (error) {
      throw new Error('API request failed:', error);
    }
  }

  async put(endpointKey, data = null, searchParam = null) {
    try {
      let endpoint = this.endpoints[endpointKey];
      if (searchParam) {
        endpoint = endpoint + '/' + searchParam;
      }
      data['app'] = GetClientAppId();
      const response = await this.api.put(endpoint, data, {
        headers: { Authorization: 'Bearer ' + GetClientTokenId() }
      });
      return response;
    } catch (error) {
      throw new Error('API request failed:', { error });
    }
  }
}
export default new APIService(BASE_URL);